import React from "react";
import "react-toastify/dist/ReactToastify.css";

const ModalConfirm = ({ open, title, message, onClose, onConfirm }) => {
  if (!open) return null;

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <div style={styles.modalBackdrop}>
      <div style={styles.modalContainer}>
        <h2>{title}</h2>
        <p>{message}</p>
        <div style={styles.modalActions}>
          <button onClick={onClose} style={styles.buttonCancel}>
            Hayır
          </button>
          <button onClick={handleConfirm} style={styles.buttonConfirm}>
            Evet
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  modalBackdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modalContainer: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    minWidth: "300px",
  },
  modalActions: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-evenly",
  },
  buttonCancel: {
    backgroundColor: "#f44336",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  buttonConfirm: {
    backgroundColor: "#4CAF50",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default ModalConfirm;
