import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const CompaniesList = ({ companies, onEdit, onDelete }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const navigate = useNavigate();

  const handleSelectCompany = (company) => {
    setSelectedCompany(selectedCompany === company ? null : company);
  };

  const handleGoToDetails = (companyId) => {
    navigate(`/companies/${companyId}`);
  };

  const sortedCompanies = [...companies].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="text-center">
            <th className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
              Ünvan
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              Bakiye
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              Eylemler
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sortedCompanies.map((company) => (
            <React.Fragment key={company._id}>
              <tr
                className="hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSelectCompany(company)}
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {company.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  <div className="flex space-x-2">
                    {/* Bakiye Tutari */}
                    <span>
                      {company.balance > 0 ? (
                        <span className="text-green-600">
                          {company.balance}
                        </span>
                      ) : (
                        <span className="text-red-600">
                          {Math.abs(company.balance)}
                        </span>
                      )}
                    </span>
                    <span>
                      {company.balance > 0 ? (
                        <span className="text-green-600">A</span>
                      ) : company.balance < 0 ? (
                        <span className="text-red-600">B</span>
                      ) : (
                        "-"
                      )}
                    </span>
                  </div>
                </td>
                <td className="ml-auto">
                  <FaEdit
                    className="text-yellow-600 hover:text-yellow-800 m-auto cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit(company);
                    }}
                  />
                </td>
              </tr>
              {selectedCompany === company && (
                <tr>
                  <td colSpan="3" className="bg-gray-100 p-4">
                    <div className="flex justify-between">
                      <div className="mb-4">
                        <p>
                          <strong>Şirket Detayları:</strong>
                        </p>
                        <p>Ünvan: {company.name}</p>
                        <p>Vergi Numarası: {company.taxNumber}</p>
                        <p>Adres: {company.address}</p>
                        <p>Telefon: {company.phoneNumber}</p>
                        <p>Email: {company.email}</p>
                        <p>IBAN: {company.iban}</p>
                      </div>
                      <div className="mb-4 flex items-center justify-center">
                        <button
                          onClick={() => handleGoToDetails(company._id)}
                          className="mt-2 px-4 py-2 bg-fuchsia-800 text-white rounded hover:bg-fuchsia-900 transition"
                        >
                          Ekstre
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompaniesList;
