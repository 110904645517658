import React, { useState, useEffect, useCallback } from "react";
import CompaniesList from "../../components/Company/CompaniesList";
import CompanyForm from "../../components/Company/CompanyForm";
import {
  getCompanies,
  updateCompany,
  deactivateCompany,
} from "../../api/companies";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import { FaTimes } from "react-icons/fa";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const CompaniesPage = () => {
  const selectedCustomer = useSelector(
    (state) => state.customers.selectedCustomer
  );
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [searchName, setSearchName] = useState("");
  const navigate = useNavigate();

  // const fetchCompanies = async () => {
  //   if (!selectedCustomer?._id) return;
  //   const data = await getCompanies(selectedCustomer._id);
  //   setCompanies(data);
  // };

  const fetchCompanies = useCallback(async () => {
    if (!selectedCustomer?._id) return;
    const data = await getCompanies(selectedCustomer._id);
    setCompanies(data);
  }, [selectedCustomer?._id]); // Sadece selectedCustomer değiştiğinde yeniden tanımlanır.

  useEffect(() => {
    if (selectedCustomer?._id) {
      fetchCompanies();
    }
  }, [selectedCustomer, fetchCompanies]);

  const filteredCompanies = companies.filter(
    (company) =>
      company.customerId === selectedCustomer?._id &&
      company?.name.toLowerCase().includes(searchName.toLowerCase())
  );

  const handleSelectCompany = (company) => {
    setSelectedCompany(company);
    navigate(`/companies/${company._id}`);
  };

  const handleCompanyAdded = (newCompany) => {
    setCompanies((prevCompanies) => [...prevCompanies, newCompany]);
    setShowModal(false);
    fetchCompanies();
  };

  const handleEditCompany = (company) => {
    if (!company._id) {
      console.error("Geçersiz şirket ID'si.");
      return;
    }
    setSelectedCompany(company);
    setShowModal(true);
  };

  const handleUpdateCompany = async (updatedCompany) => {
    const response = await updateCompany(updatedCompany);
    setCompanies((prevCompanies) =>
      prevCompanies.map((c) => (c._id === response._id ? response : c))
    );
    setShowModal(false);
    setSelectedCompany(null);
    fetchCompanies();
  };

  const handleDeleteClick = async (company) => {
    try {
      const hasPaymentsOrInvoices = await checkCompanyPaymentsOrInvoices(
        company._id
      );

      if (hasPaymentsOrInvoices) {
        const { value: enteredPassword } = await Swal.fire({
          title: "Şifre Girişi",
          text: "Şirket ile ilgili kayıtlar vardır. Buna rağmen silmek istiyorsanız şifreyi girin:",
          input: "password",
          inputPlaceholder: "Şifrenizi girin",
          showCancelButton: true,
          confirmButtonText: "Onayla",
          cancelButtonText: "İptal",
          inputValidator: (value) => {
            if (!value) return "Şifreyi girmeniz gerekmektedir!";
          },
        });

        if (enteredPassword !== "Deneme123") {
          Swal.fire({
            icon: "error",
            title: "Yanlış şifre!",
            text: "Yönlendiriliyorsunuz",
          });
          return;
        }
      }

      const confirmDelete = await Swal.fire({
        title: "Emin misiniz?",
        text: hasPaymentsOrInvoices
          ? "Ünvanı silmek istediğinizden emin misiniz?"
          : "Şirketi silmek istediğinizden emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet, Sil",
        cancelButtonText: "Hayır",
      });

      if (confirmDelete.isConfirmed) {
        await deactivateCompany(company._id);
        setCompanies((prevCompanies) =>
          prevCompanies.filter((c) => c._id !== company._id)
        );
        Swal.fire({
          icon: "success",
          title: "Başarılı!",
          text: "Şirket başarıyla silindi.",
        });
      }
    } catch (error) {
      console.error("Kayıt kontrolü sırasında bir hata oluştu:", error.message);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Kayıt kontrolü sırasında bir hata oluştu.",
      });
    }
  };

  const checkCompanyPaymentsOrInvoices = async (companyId) => {
    try {
      const response = await fetch(`/api/companies/${companyId}/has-records`);
      const data = await response.json();
      return data.hasRecords;
    } catch (error) {
      console.error("Kayıtlar kontrol edilirken hata:", error);
      return false;
    }
  };

  return (
    <div className="p-4  flex flex-col items-center h-screen">
      <h1 className="text-2xl font-bold mb-4 text-center">Şirketler</h1>

      <div className="w-full max-w-lg bg-white rounded-lg shadow-md">
        <div className="p-4 border-b border-gray-300">
          <div className="">
            <input
              type="text"
              placeholder="Ünvan Ara"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              className="border z-10 rounded-lg w-full p-2 mb-2 pr-10"
            />
            {searchName && (
              <button
                onClick={() => setSearchName("")}
                className="absolute inset-y-1 right-3 flex items-center pl-2"
              >
                <FaTimes className="text-gray-400 hover:text-gray-600" />
              </button>
            )}
          </div>
          <button
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition w-full"
            onClick={() => {
              setSelectedCompany(null);
              setShowModal(true);
            }}
          >
            Şirket Ekle
          </button>
        </div>

        <div className="border-b border-gray-300 pb-4 mb-4">
          <CompaniesList
            companies={filteredCompanies}
            onSelect={handleSelectCompany}
            onEdit={handleEditCompany}
            onDelete={handleDeleteClick}
          />
        </div>
      </div>

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <CompanyForm
          onCompanyAdded={handleCompanyAdded}
          onUpdateCompany={handleUpdateCompany}
          initialData={selectedCompany}
          customerId={selectedCustomer?._id}
        />
      </Modal>
    </div>
  );
};

export default CompaniesPage;
