import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCustomers,
  addCustomer,
  updateCustomer,
  deleteCustomer,
} from "../../api/customer";

export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async () => {
    const response = await getCustomers();
    return response;
  }
);

export const createCustomer = createAsyncThunk(
  "customers/createCustomer",
  async (customerData) => {
    const response = await addCustomer(customerData);
    return response.data;
  }
);

export const modifyCustomer = createAsyncThunk(
  "customers/modifyCustomer",
  async (customerData) => {
    const response = await updateCustomer(customerData);
    return response.data;
  }
);

export const removeCustomer = createAsyncThunk(
  "customers/removeCustomer",
  async (customerId) => {
    await deleteCustomer(customerId);
    return customerId;
  }
);

const customersSlice = createSlice({
  name: "customers",
  initialState: {
    list: [],
    selectedCustomer: null,
    loading: false,
    error: null,
  },
  reducers: {
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.loading = false;
        if (Array.isArray(action.payload)) {
          state.list = action.payload;
        } else {
          state.error = "Veri düzgün yüklenmedi.";
        }
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.list.push(action.payload);
      })
      .addCase(modifyCustomer.fulfilled, (state, action) => {
        const index = state.list.findIndex(
          (customer) => customer._id === action.payload._id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      })
      .addCase(removeCustomer.fulfilled, (state, action) => {
        state.list = state.list.filter(
          (customer) => customer._id !== action.payload
        );
      });
  },
});

export const { setSelectedCustomer } = customersSlice.actions;
export default customersSlice.reducer;
