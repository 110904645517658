import React, { useState, useEffect } from "react";
import { addCompany, updateCompany } from "../../api/companies";
import { toast } from "react-toastify";

const CompanyForm = ({
  onCompanyAdded,
  onUpdateCompany,
  initialData,
  customerId,
}) => {
  const [name, setName] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [iban, setIban] = useState("TR");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (initialData) {
      setName(initialData.name || "");
      setTaxNumber(initialData.taxNumber || "");
      setAddress(initialData.address || "");
      setPhoneNumber(initialData.phoneNumber || "");
      setEmail(initialData.email || "");
      setIban(initialData.iban || "TR");

      if (!initialData._id) {
        console.error("Güncellenmesi gereken şirket ID'si bulunamadı.");
      }
    }
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (taxNumber.length < 10 || taxNumber.length > 11) {
      toast.error("Vergi numarasını kontrol edin!");
      return;
    }

    if (isSubmitting) return;
    setIsSubmitting(true);

    const companyData = {
      name,
      taxNumber,
      address,
      phoneNumber,
      email,
      iban,
      customerId,
    };

    try {
      if (initialData) {
        if (!initialData._id) {
          toast.error("Şirket ID'si bulunamadı!");
          return;
        }

        const updatedCompany = await updateCompany({
          ...companyData,
          _id: initialData._id,
        });
        onUpdateCompany(updatedCompany);
      } else {
        const addedCompany = await addCompany(companyData, customerId);
        onCompanyAdded(addedCompany);
      }

      toast.success(
        `Şirket başarıyla ${initialData ? "güncellendi" : "eklendi"}!`,
        {
          position: "top-right",
          autoClose: 3000,
        }
      );

      setName("");
      setTaxNumber("");
      setAddress("");
      setPhoneNumber("");
      setEmail("");
      setIban("TR");
    } catch (error) {
      console.error("Hata:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleIbanChange = (e) => {
    const input = e.target.value.replace(/[^0-9]/g, "").slice(0, 24);

    const formattedIban = `TR ${input.slice(0, 2)} ${input
      .slice(2)
      .replace(/(.{4})/g, "$1 ")
      .trim()}`;
    setIban(formattedIban);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-4 p-4 border border-gray-300 rounded shadow-md"
    >
      <h2 className="text-lg font-semibold">
        {initialData ? "Şirketi Güncelle" : "Yeni Şirket Ekle"}
      </h2>
      <input
        type="text"
        placeholder="Ünvan"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-sm"
      />
      <input
        type="text"
        placeholder="TCKN / VKN"
        value={taxNumber}
        onChange={(e) => setTaxNumber(e.target.value)}
        required
        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-sm"
        maxLength={11}
      />
      <input
        type="text"
        placeholder="Adres"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-sm"
        maxLength={150}
      />
      <input
        type="text"
        placeholder="Telefon Numarası"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-sm"
        maxLength={15}
      />
      <input
        type="text"
        placeholder="E-posta adresi"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-sm"
        maxLength={50}
      />
      <input
        type="text"
        placeholder="IBAN (TRXX XXXX XXXX XXXX XXXX XXXX XXXX)"
        value={iban}
        onChange={handleIbanChange}
        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-sm"
        maxLength={33}
        required
      />
      <button
        type="submit"
        className="w-full py-2 bg-green-600 text-white rounded hover:bg-green-700 transition"
        disabled={isSubmitting}
      >
        {initialData ? "Güncelle" : "Ekle"}
      </button>
    </form>
  );
};

export default CompanyForm;
