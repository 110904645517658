import React, { useState, useEffect } from "react";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addInvoice } from "../../api/invoices";
import { toast } from "react-toastify";
import { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";

registerLocale("tr", tr);
setDefaultLocale("tr");

const InvoiceForm = ({
  onInvoiceAdded,
  companyId,
  companies = [],
  closeModal,
  selectedCustomerId,
}) => {
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(companyId || "");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (companyId) {
      setSelectedCompany(companyId);
    }
  }, [companyId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    const newInvoice = {
      invoiceNumber: invoiceNumber.toUpperCase(),
      date,
      amount: Number(amount),
      description: description.toUpperCase(),
      companyId: selectedCompany,
      customerId: selectedCustomerId,
    };

    try {
      await addInvoice(newInvoice);
      closeModal();
      onInvoiceAdded();
      setInvoiceNumber("");
      setDate(new Date());
      setAmount("");
      setDescription("");
      toast.success("Fatura başarıyla eklendi.", {
        position: "top-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Fatura eklerken hata:", error);
      toast.error("Fatura eklenirken bir hata oluştu.", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const filteredCompanies = companies.filter(
    (company) => String(company.customerId) === String(selectedCustomerId)
  );

  useEffect(() => {
    if (
      (!selectedCompany ||
        !filteredCompanies.some(
          (company) => company._id === selectedCompany
        )) &&
      filteredCompanies.length > 0
    ) {
      setSelectedCompany(filteredCompanies[0]._id);
    } else if (filteredCompanies.length === 0) {
      setSelectedCompany("");
    }
  }, [filteredCompanies, selectedCompany]);

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-4 border border-gray-300 rounded shadow-md p-5"
    >
      <div className="mb-4">
        <label className="block">Şirket</label>
        <select
          value={selectedCompany}
          onChange={(e) => setSelectedCompany(e.target.value)}
          required
          className="w-full border px-4 py-2"
        >
          {filteredCompanies.length === 0 ? (
            <option disabled>Bu şube için şirket bulunamadı.</option>
          ) : (
            filteredCompanies.map((company) => (
              <option key={company._id} value={company._id}>
                {company.name}
              </option>
            ))
          )}
        </select>
      </div>

      <div>
        <label className="block">Fatura No</label>
        <input
          type="text"
          value={invoiceNumber}
          onChange={(e) => setInvoiceNumber(e.target.value)}
          required
          className="w-full border px-4 py-2"
          maxLength={16}
        />
      </div>

      <div>
        <label className="block">Tarih</label>
        <DatePicker
          selected={date}
          onChange={(date) => setDate(date)}
          dateFormat="dd/MM/yyyy"
          locale="tr"
          className="w-full border px-4 py-2"
          required
        />
      </div>

      <div>
        <label className="block">Tutar</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
          className="w-full border px-4 py-2"
          step="0.01"
          max="100000000"
        />
      </div>

      <div>
        <label className="block">Açıklama</label>
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className="w-full border px-4 py-2"
          maxLength={50}
        />
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={closeModal}
          className="bg-gray-500 text-white px-4 py-2 rounded-lg"
        >
          Kapat
        </button>
        <button
          type="submit"
          className="bg-red-600 px-2 py-1 text-white rounded-lg hover:bg-red-300 hover:text-black transition"
          disabled={filteredCompanies.length === 0 || isSubmitting}
        >
          {isSubmitting ? "Yükleniyor..." : "Fatura Ekle"}
        </button>
      </div>
    </form>
  );
};

export default InvoiceForm;
