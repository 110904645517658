import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../redux/slices/customersSlice";
import Card from "../components/Card";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { list, loading, error } = useSelector((state) => state.customers);

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  if (loading) {
    return <p>Yükleniyor...</p>;
  }

  if (error) {
    return <p>Hata: {error}</p>;
  }

  return (
    <div>
      <div className="cards-container" style={styles.cardsContainer}>
        {list.map((customer) => (
          <Card key={customer._id} customer={customer} />
        ))}
      </div>
    </div>
  );
};

const styles = {
  cardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
  },
};

export default Dashboard;
