import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoices } from "../../redux/slices/invoicesSlice";
import { getCompanies } from "../../api/companies";
import { deleteInvoice, apiUpdateInvoice } from "../../api/invoices";
import InvoiceList from "../../components/Invoice/InvoiceList";
import Modal from "../../components/Modal";
import InvoiceForm from "../../components/Invoice/InvoiceForm";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const InvoicesPage = () => {
  const dispatch = useDispatch();

  const { list: invoices, loading } = useSelector((state) => state.invoices);
  const selectedCustomer = useSelector(
    (state) => state.customers.selectedCustomer
  );
  const [companies, setCompanies] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const fetchCompanies = useCallback(async () => {
    try {
      if (!selectedCustomer?._id) return;
      const data = await getCompanies(selectedCustomer._id);
      setCompanies(data);
    } catch (error) {
      console.error("Şirketler alınırken hata:", error);
    }
  }, [selectedCustomer]);

  const onInvoiceDelete = async (invoiceId) => {
    try {
      await deleteInvoice(invoiceId, selectedCustomer._id);
      dispatch(fetchInvoices(selectedCustomer._id));
    } catch (error) {
      console.error("Fatura silinirken hata:", error.message);
      toast.error("Fatura silinirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  const onInvoiceUpdate = async (invoiceId, updatedData) => {
    try {
      const updatedInvoice = await apiUpdateInvoice(
        invoiceId,
        updatedData,
        selectedCustomer._id
      );
      if (updatedInvoice) {
        dispatch(fetchInvoices(selectedCustomer._id));
      } else {
        console.error("Güncellenen fatura bulunamadı");
      }
    } catch (error) {
      console.error("Fatura güncellenirken hata:", error.message);
    }
  };

  useEffect(() => {
    if (selectedCustomer?._id) {
      dispatch(fetchInvoices(selectedCustomer._id));
      fetchCompanies();
    }
  }, [selectedCustomer, dispatch, fetchCompanies]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold text-center text-blue-800 mb-4">
        Fatura Listesi
      </h1>
      <div className="gap-4 flex">
        <button
          className="mb-4 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-300 hover:text-black transition"
          onClick={() => setShowModal(true)}
        >
          Fatura Ekle
        </button>
      </div>

      {selectedCustomer ? (
        <InvoiceList
          invoices={invoices}
          companies={companies}
          onInvoiceUpdate={onInvoiceUpdate}
          onInvoiceDelete={onInvoiceDelete}
        />
      ) : (
        <p>Seçili Şube yok, lütfen bir Şube seçin.</p>
      )}

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <InvoiceForm
          companies={companies}
          onInvoiceAdded={() => dispatch(fetchInvoices(selectedCustomer._id))}
          closeModal={() => setShowModal(false)}
          selectedCustomerId={selectedCustomer?._id}
        />
      </Modal>
    </div>
  );
};

export default InvoicesPage;
