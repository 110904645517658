import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { fetchPayments } from "../../redux/slices/paymentsSlice";
import { toast } from "react-toastify";
import ModalConfirm from "../../components/modalConfirm";

const PaymentList = ({
  payments = [],
  companies,
  onPaymentUpdate,
  onPaymentDelete,
  selectedCustomerId,
}) => {
  const [editablePaymentId, setEditablePaymentId] = useState(null);
  const [editedPaymentData, setEditedPaymentData] = useState({
    date: "",
    amount: "",
    companyId: "",
    description: "",
    documentNumber: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const dispatch = useDispatch();

  const handleEditClick = (payment) => {
    setEditablePaymentId(payment._id);
    setEditedPaymentData({
      date: new Date(payment.date).toISOString().substring(0, 10),
      amount: payment.amount,
      companyId: payment.companyId ? payment.companyId._id : "",
      description: payment.description,
      documentNumber: payment.documentNumber,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedPaymentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCompanyChange = (e) => {
    setEditedPaymentData((prevData) => ({
      ...prevData,
      companyId: e.target.value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      const updatedPaymentData = {
        ...editedPaymentData,
        companyId: editedPaymentData.companyId,
      };

      await onPaymentUpdate(editablePaymentId, updatedPaymentData);
      setEditablePaymentId(null);
      dispatch(fetchPayments({ customerId: selectedCustomerId }));
    } catch (error) {
      console.error("Ödeme güncellenirken hata:", error.message);
    }
  };

  const handleCancelClick = () => {
    setEditablePaymentId(null);
  };

  const handleDeleteClick = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setOpenModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await onPaymentDelete(selectedPaymentId);
      dispatch(fetchPayments({ customerId: selectedCustomerId }));
    } catch (error) {
      console.error("Ödeme silinirken hata:", error);
      toast.error("Ödeme silinirken bir hata oluştu.");
    }

    setOpenModal(false);
  };

  const handleCancelDelete = () => {
    setOpenModal(false);
  };
  const formatAmount = (amount) => {
    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="bg-green-100">
              <TableCell>Tarih</TableCell>
              <TableCell>Şirket</TableCell>
              <TableCell>Belge No</TableCell>
              <TableCell align="right">Tutar</TableCell>
              <TableCell>Açıklama</TableCell>
              <TableCell>İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(payments) && payments.length > 0 ? (
              payments
                .slice()
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((payment) => (
                  <TableRow key={payment._id} hover>
                    <TableCell>
                      {editablePaymentId === payment._id ? (
                        <TextField
                          type="date"
                          name="date"
                          value={editedPaymentData.date}
                          onChange={handleInputChange}
                          variant="outlined"
                          size="small"
                        />
                      ) : (
                        new Date(payment.date).toLocaleDateString()
                      )}
                    </TableCell>
                    <TableCell>
                      {editablePaymentId === payment._id ? (
                        <Select
                          name="companyId"
                          value={editedPaymentData.companyId || ""}
                          onChange={handleCompanyChange}
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled
                        >
                          <MenuItem value="">Şirket Seçin</MenuItem>
                          {companies.map((company) => (
                            <MenuItem key={company._id} value={company._id}>
                              {company.name}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : payment.companyId ? (
                        payment.companyId.name
                      ) : (
                        "Şirket Bilgisi Yok"
                      )}
                    </TableCell>
                    <TableCell>
                      {editablePaymentId === payment._id ? (
                        <TextField
                          type="text"
                          name="documentNumber"
                          value={editedPaymentData.documentNumber}
                          onChange={handleInputChange}
                          variant="outlined"
                          size="small"
                        />
                      ) : (
                        payment.documentNumber
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {editablePaymentId === payment._id ? (
                        <TextField
                          type="number"
                          name="amount"
                          value={editedPaymentData.amount}
                          onChange={handleInputChange}
                          variant="outlined"
                          size="small"
                        />
                      ) : (
                        formatAmount(payment.amount)
                      )}
                    </TableCell>
                    <TableCell>
                      {editablePaymentId === payment._id ? (
                        <TextField
                          type="text"
                          name="description"
                          value={editedPaymentData.description}
                          onChange={handleInputChange}
                          variant="outlined"
                          size="small"
                        />
                      ) : (
                        payment.description
                      )}
                    </TableCell>
                    <TableCell>
                      {editablePaymentId === payment._id ? (
                        <>
                          <Button
                            color="primary"
                            onClick={handleSaveClick}
                            size="small"
                          >
                            Kaydet
                          </Button>
                          <Button
                            color="secondary"
                            onClick={handleCancelClick}
                            size="small"
                          >
                            İptal
                          </Button>
                        </>
                      ) : (
                        <>
                          <IconButton
                            color="warning"
                            onClick={() => handleEditClick(payment)}
                          >
                            <FaEdit />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteClick(payment._id)}
                          >
                            <FaTrashAlt />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan="6" align="center">
                  <Typography color="textSecondary">
                    Henüz ödeme bulunmamaktadır.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalConfirm
        open={openModal}
        title="Ödeme Silme Onayı"
        message="Bu ödemeyi silmek istediğinizden emin misiniz?"
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default PaymentList;
