import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalConfirm from "../../components/modalConfirm";

const formatAmount = (amount) => {
  return new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

const InvoiceList = ({
  invoices,
  companies,
  onInvoiceUpdate,
  onInvoiceDelete,
  selectedCustomerId,
}) => {
  const [editableInvoiceId, setEditableInvoiceId] = useState(null);
  const [editedInvoiceData, setEditedInvoiceData] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);


  const handleEditClick = (invoice) => {
    setEditableInvoiceId(invoice._id);
    setEditedInvoiceData({
      invoiceNumber: invoice.invoiceNumber,
      date: new Date(invoice.date).toISOString().substring(0, 10),
      amount: invoice.amount,
      description: invoice.description,
      companyId: invoice.companyId ? invoice.companyId._id : "",
    });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedInvoiceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    try {
      const amount = parseFloat(editedInvoiceData.amount);
      if (isNaN(amount)) {
        throw new Error("Geçerli bir tutar giriniz.");
      }

      await onInvoiceUpdate(editableInvoiceId, {
        ...editedInvoiceData,
        amount,
        companyId: editedInvoiceData.companyId,
      });

      setEditableInvoiceId(null);
      toast.success("Fatura başarıyla güncellendi.");
    } catch (error) {
      toast.error(`Hata: ${error.message}`);
    }
  };

  const handleCancelClick = () => {
    setEditableInvoiceId(null);
    setEditedInvoiceData({});
  };

  const handleDeleteClick = (invoiceId) => {
    setInvoiceToDelete(invoiceId);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await onInvoiceDelete(invoiceToDelete);
      setInvoiceToDelete(null);
      toast.success("Fatura başarıyla silindi.");
    } catch (error) {
      toast.error("Fatura silinirken bir hata oluştu.");
    }
  };


  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
    setInvoiceToDelete(null);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tarih</TableCell>
              <TableCell>Şirket</TableCell>
              <TableCell>Belge No</TableCell>
              <TableCell>Tutar</TableCell>
              <TableCell>Açıklama</TableCell>
              <TableCell>İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(invoices) && invoices.length > 0 ? (
              invoices
                .slice()
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((invoice) => (
                  <TableRow key={invoice._id} hover>
                    <TableCell>
                      {editableInvoiceId === invoice._id ? (
                        <TextField
                          type="date"
                          name="date"
                          value={editedInvoiceData.date}
                          onChange={handleInputChange}
                          variant="outlined"
                          size="small"
                        />
                      ) : (
                        new Date(invoice.date).toLocaleDateString()
                      )}
                    </TableCell>
                    <TableCell>
                      {editableInvoiceId === invoice._id ? (
                        <TextField
                          name="companyId"
                          value={
                            invoice.companyId ? invoice.companyId.name : ""
                          }
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled
                        />
                      ) : (
                        (invoice.companyId && invoice.companyId.name) ||
                        "Şirket Bilgisi Yok"
                      )}
                    </TableCell>
                    <TableCell>
                      {editableInvoiceId === invoice._id ? (
                        <TextField
                          type="text"
                          name="invoiceNumber"
                          value={editedInvoiceData.invoiceNumber}
                          onChange={handleInputChange}
                          variant="outlined"
                          size="small"
                        />
                      ) : (
                        invoice.invoiceNumber
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {editableInvoiceId === invoice._id ? (
                        <TextField
                          type="number"
                          name="amount"
                          value={editedInvoiceData.amount}
                          onChange={handleInputChange}
                          variant="outlined"
                          size="small"
                          InputProps={{
                            style: { textAlign: "right" },
                          }}
                        />
                      ) : (
                        <span>{formatAmount(invoice.amount)}</span>
                      )}
                    </TableCell>
                    <TableCell>
                      {editableInvoiceId === invoice._id ? (
                        <TextField
                          type="text"
                          name="description"
                          value={editedInvoiceData.description}
                          onChange={handleInputChange}
                          variant="outlined"
                          size="small"
                        />
                      ) : (
                        invoice.description
                      )}
                    </TableCell>
                    <TableCell>
                      {editableInvoiceId === invoice._id ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Button
                            color="primary"
                            onClick={handleSaveClick}
                            size="small"
                          >
                            Kaydet
                          </Button>
                          <Button
                            color="secondary"
                            onClick={handleCancelClick}
                            size="small"
                          >
                            İptal
                          </Button>
                        </div>
                      ) : (
                        <>
                          <IconButton
                            color="warning"
                            onClick={() => handleEditClick(invoice)}
                          >
                            <FaEdit />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteClick(invoice._id)}
                          >
                            <FaTrashAlt />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan="6" align="center">
                  <Typography color="textSecondary">
                    Henüz fatura bulunmamaktadır.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ModalConfirm
        open={openConfirmDialog}
        title="Fatura Silme"
        message="Faturayı silmek istediğinizden emin misiniz?"
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default InvoiceList;
