import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000/";
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";

export const setAuthHeaders = (customerId, companyId) => {
  axios.defaults.headers.common["customer-id"] = customerId;
  axios.defaults.headers.common["company-id"] = companyId;
};

export const getPayments = async (customerId) => {
  try {
    const response = await axios.get("payments", {
      params: { customerId },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Ödemeleri getirirken hata:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const addPayment = async (paymentData, customerId, companyId) => {
  setAuthHeaders(customerId, companyId);

  try {
    const response = await axios.post("payments", paymentData);
    return response.data;
  } catch (error) {
    console.error(
      "Ödeme eklenirken hata:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const updatePayment = async (paymentId, updatedData, customerId) => {
  setAuthHeaders(customerId);

  try {
    const response = await axios.put(`payments/${paymentId}`, updatedData);
    return response.data;
  } catch (error) {
    console.error(
      "Ödeme güncellenirken hata:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const deletePayment = async (paymentId, customerId) => {
  setAuthHeaders(customerId);

  try {
    const response = await axios.delete(`payments/${paymentId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Ödeme silinirken hata:",
      error.response?.data || error.message
    );
    throw error;
  }
};
