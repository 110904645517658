import React from "react";
import ReactDOM from "react-dom";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      onClick={() => onClose()}
    >
      <div
        className=" rounded-lg shadow-lg w-96 bg-slate-200 p-6 relative"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
        <button
          className="mt-2 px-4 py-2 absolute top-[-50px] z-10 right-2 bg-red-600 text-white rounded hover:bg-red-700 transition"
          onClick={() => onClose()}
        >
          X
        </button>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
