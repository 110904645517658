import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCustomers,
  setSelectedCustomer,
} from "../redux/slices/customersSlice";

const Header = () => {
  const dispatch = useDispatch();
  const { list: customers, selectedCustomer } = useSelector(
    (state) => state.customers
  );
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const customerDropdownRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const hamburgerRef = useRef(null);

  useEffect(() => {
    dispatch(fetchCustomers());

    const handleClickOutside = (event) => {
      if (
        customerDropdownRef.current &&
        !customerDropdownRef.current.contains(event.target)
      ) {
        setIsCustomerDropdownOpen(false);
      }
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const handleCustomerSelect = (customer) => {
    if (selectedCustomer && selectedCustomer._id === customer._id) {
      setIsCustomerDropdownOpen(false);
      return;
    }

    dispatch(setSelectedCustomer(customer));
    setIsCustomerDropdownOpen(false);
    setIsMobileMenuOpen(false);
  };

  return (
    <header className="flex justify-between items-center z-20 p-4 bg-gray-800 text-white shadow-md relative">
      <div className="flex items-center w-full justify-between md:hidden">
        <button
          ref={hamburgerRef}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="text-white focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      <div className="relative">
        <div className="relative left-0 w-full" ref={customerDropdownRef}>
          <button
            onClick={() => setIsCustomerDropdownOpen(!isCustomerDropdownOpen)}
            className="bg-gray-700 text-white px-4 py-2 w-[200px] rounded-md focus:outline-none"
          >
            {selectedCustomer ? selectedCustomer.name : "Şube Seçin"}
          </button>

          {isCustomerDropdownOpen && (
            <ul className="absolute right-0 md:left-0 mt-2 bg-white text-gray-800 shadow-lg rounded-md w-48 z-40">
              {customers.length > 0 ? (
                customers.map((customer) => (
                  <li key={customer._id}>
                    <button
                      onClick={() => handleCustomerSelect(customer)}
                      className="w-full px-4 py-2 text-left hover:bg-gray-100"
                    >
                      {customer.name}
                    </button>
                  </li>
                ))
              ) : (
                <li className="px-4 py-2 text-gray-500">Şube bulunamadı</li>
              )}
            </ul>
          )}
        </div>
      </div>

      <div className="hidden md:flex items-center w-full justify-between">
        <nav className="flex space-x-4 justify-center flex-1">
          <ul className="flex space-x-4 ">
            <li>
              <Link to="/" className="hover:bg-gray-700 px-3 py-2 rounded-md">
                Ana Sayfa
              </Link>
            </li>
            <li>
              <Link
                to="/companies"
                className="hover:bg-gray-700 px-3 py-2 rounded-md"
              >
                Şirketler
              </Link>
            </li>
            <li>
              <Link
                to="/invoices"
                className="hover:bg-gray-700 px-3 py-2 rounded-md"
              >
                Faturalar
              </Link>
            </li>
            <li>
              <Link
                to="/payments"
                className="hover:bg-gray-700 px-3 py-2 rounded-md"
              >
                Ödemeler
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="hover:bg-gray-700 px-3 py-2 rounded-md"
              >
                Hakkında
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      {isMobileMenuOpen && (
        <nav
          ref={mobileMenuRef}
          className="absolute top-[50px] left-0 w-25 bg-gray-800 text-white shadow-md md:hidden z-40"
        >
          <ul className="space-y-5 p-4 text-align-items-center items-center">
            <li>
              <Link
                to="/"
                className="hover:bg-gray-700 px-3 py-2 rounded-md"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Ana Sayfa
              </Link>
            </li>
            <li>
              <Link
                to="/companies"
                className="hover:bg-gray-700 px-3 py-2 rounded-md"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Şirketler
              </Link>
            </li>
            <li>
              <Link
                to="/invoices"
                className="hover:bg-gray-700 px-3 py-2 rounded-md"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Faturalar
              </Link>
            </li>
            <li>
              <Link
                to="/payments"
                className="hover:bg-gray-700 px-3 py-2 rounded-md"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Ödemeler
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="hover:bg-gray-700 px-3 py-2 rounded-md"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Hakkında
              </Link>
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
