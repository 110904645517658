const API_URL = process.env.REACT_APP_API_URL;

export const getCustomers = async () => {
  try {
    const response = await fetch(`${API_URL}customers`);

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage || "Şubeler alınamadı");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Şubeler alınırken hata:", error);
    throw error;
  }
};

export const addCustomer = async (customer) => {
  try {
    const response = await fetch(`${API_URL}customers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: customer.name }),
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage || "Şube eklenemedi");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Şube eklenirken hata:", error);
    throw error;
  }
};

export const updateCustomer = async (customerData) => {
  try {
    const response = await fetch(`${API_URL}customers/${customerData._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: customerData.name }),
    });

    if (!response.ok) {
      throw new Error("Şube güncellenemedi");
    }

    return response.json();
  } catch (error) {
    console.error("Şube güncellenirken hata:", error);
    throw error;
  }
};

export const deleteCustomer = async (customerId) => {
  try {
    const response = await fetch(`${API_URL}customers/${customerId}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage || "Şube silinemedi");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Şube silinirken hata:", error);
    throw error;
  }
};
