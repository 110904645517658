import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayments } from "../../redux/slices/paymentsSlice";
import { getCompanies } from "../../api/companies";
import { deletePayment, updatePayment, addPayment } from "../../api/payments";
import PaymentList from "../../components/Payment/PaymentList";
import Modal from "../../components/Modal";
import PaymentForm from "../../components/Payment/PaymentForm";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const PaymentsPage = () => {
  const dispatch = useDispatch();
  const { payments, loading } = useSelector((state) => state.payments);
  const selectedCustomer = useSelector(
    (state) => state.customers.selectedCustomer
  );

  const [companies, setCompanies] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const fetchCompanies = async (customerId) => {
    try {
      const companiesData = await getCompanies(customerId);
      setCompanies(companiesData);
    } catch (error) {
      console.error("Şirketler alınırken hata:", error);
    }
  };

  const handlePaymentDelete = async (paymentId) => {
    try {
      await deletePayment(paymentId);
      dispatch(fetchPayments({ customerId: selectedCustomer._id }));
      toast.success("Ödeme başarıyla silindi.");
    } catch (error) {
      console.error("Ödeme silinirken hata:", error);
      toast.error("Ödeme silinirken bir hata oluştu.");
    }
  };

  const handlePaymentUpdate = async (paymentId, updatedData) => {
    try {
      await updatePayment(paymentId, updatedData, selectedCustomer._id);
      dispatch(fetchPayments({ customerId: selectedCustomer._id }));
      toast.success("Ödeme başarıyla güncellendi.");
    } catch (error) {
      console.error("Ödeme güncellenirken hata:", error);
      toast.error("Ödeme güncellenirken bir hata oluştu.");
    }
  };

  const handleAddPayment = async (paymentData) => {
    try {
      await addPayment(
        paymentData,
        selectedCustomer._id,
        paymentData.companyId
      );
      dispatch(fetchPayments({ customerId: selectedCustomer._id }));
      setShowModal(false);
      toast.success("Ödeme başarıyla eklendi.");
    } catch (error) {
      console.error("Ödeme eklenirken hata:", error);
      toast.error("Ödeme eklenirken bir hata oluştu.");
    }
  };

  useEffect(() => {
    if (selectedCustomer?._id) {
      dispatch(fetchPayments({ customerId: selectedCustomer._id }));
      fetchCompanies(selectedCustomer._id);
    }
  }, [selectedCustomer, dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold text-center text-blue-800 mb-4">
        Ödeme Listesi
      </h1>
      <div className="gap-4 flex">
        <button
          className="mb-4 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-300 hover:text-black transition"
          onClick={() => setShowModal(true)}
        >
          Ödeme Ekle
        </button>
      </div>

      {selectedCustomer ? (
        <PaymentList
          payments={payments}
          companies={companies}
          onPaymentUpdate={handlePaymentUpdate}
          onPaymentDelete={handlePaymentDelete}
        />
      ) : (
        <p>Seçili müşteri yok, lütfen bir müşteri seçin.</p>
      )}

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <PaymentForm
          companies={companies}
          onAddPayment={handleAddPayment}
          closeModal={() => setShowModal(false)}
          selectedCustomerId={selectedCustomer?._id}
        />
      </Modal>
    </div>
  );
};

export default PaymentsPage;
