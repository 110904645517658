import React from "react";

function About() {
  return (
    <div>
      {/* <h1>Hakkında</h1> */}
      Design by <a rel="noreferrer" target="_blank" className="text-blue-800 underline" href="https://github.com/omerfaruk2626">Ömer Faruk Sivri</a>
    </div>
  );
}

export default About;
