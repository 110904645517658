import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCompanyDetails } from "../../api/companies";
import CompanyDetail from "../../components/Company/CompanyDetail";
import Loader from "../../components/Loader";
import { FaArrowLeft } from "react-icons/fa";
import { Modal } from "@mui/material";
import CompanyInvoiceForm from "../../components/Company/CompanyInvoiceForm";
import CompanyPaymentForm from "../../components/Company/CompanyPaymentForm";
import { toast } from "react-toastify";

const CompanyDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isInvoiceSubmitting, setIsInvoiceSubmitting] = useState(false);
  const [isPaymentSubmitting, setIsPaymentSubmitting] = useState(false);
  const selectedCustomer = useSelector(
    (state) => state.customers.selectedCustomer
  );
  const customerId = selectedCustomer ? selectedCustomer._id : null;

  const fetchCompany = useCallback(async () => {
    try {
      const companyDetails = await getCompanyDetails(id, customerId);
      setCompany(companyDetails);
    } catch (error) {
      console.error("Şirket bilgileri alınırken hata:", error);
    }
  }, [id, customerId]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  const handleInvoiceAdded = async () => {
    try {
      await fetchCompany();

      setShowInvoiceModal(false);

      toast.success("Fatura başarıyla eklendi.", {
        position: "top-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Fatura ekleme hatası:", error);
      toast.error("Fatura eklenirken bir hata oluştu.", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsInvoiceSubmitting(false);
    }
  };

  const handlePaymentAdded = async (newPayment) => {
    if (isPaymentSubmitting) return;
    setIsPaymentSubmitting(true);

    try {
      await fetchCompany();

      setShowPaymentModal(false);
      toast.success("Ödeme başarıyla eklendi.", {
        position: "top-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Ödeme ekleme hatası:", error);
    } finally {
      setIsPaymentSubmitting(false);
    }
  };
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  if (!company) return <Loader />;

  const allTransactions = [
    ...company.invoices.map((invoice) => ({
      ...invoice,
      type: "invoice",
      date: new Date(invoice.date),
    })),
    ...company.payments.map((payment) => ({
      ...payment,
      type: "payment",
      date: new Date(payment.date),
    })),
  ];

  const sortedTransactions = allTransactions
    .sort((a, b) => a.date - b.date)
    .reverse();

  return (
    <div className="p-4 bg-gray-100 min-h-screen container mx-auto">
      <button
        className="mt-4 mb-4 px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-gray-700 transition flex items-center justify-center w-full"
        onClick={() => navigate("/companies")}
      >
        <FaArrowLeft className="mr-2" /> Şirketler Listesine Dön
      </button>
      <h1 className="text-2xl font-bold mb-4">{company?.name}</h1>
      <div>
        <p className="text-sm text-gray-600">
          Vergi Numarası: {company.taxNumber}
        </p>
        <p className="text-sm text-gray-600">Adres: {company.address}</p>
        <p className="text-sm text-gray-600">Telefon: {company.phoneNumber}</p>
        <p className="text-sm text-gray-600">Email: {company.email}</p>
        <p className="text-sm text-gray-600">IBAN Numarası: {company.iban}</p>
      </div>

      <div className="gap-4 mt-3 flex">
        <button
          className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-300 hover:text-black transition"
          onClick={() => setShowInvoiceModal(true)}
        >
          Fatura Ekle
        </button>
        <button
          className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-300 hover:text-black transition"
          onClick={() => setShowPaymentModal(true)}
        >
          Ödeme Ekle
        </button>
      </div>

      <CompanyDetail
        company={company}
        sortedTransactions={sortedTransactions}
        formatCurrency={formatCurrency}
      />

      <Modal
        open={showInvoiceModal}
        onClose={() => setShowInvoiceModal(false)}
        aria-labelledby="invoice-modal-title"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20">
          <CompanyInvoiceForm
            onInvoiceAdded={handleInvoiceAdded}
            companyId={company?._id}
            closeModal={() => setShowInvoiceModal(false)}
            isSubmitting={isInvoiceSubmitting}
          />
        </div>
      </Modal>

      <Modal
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        aria-labelledby="payment-modal-title"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20">
          <CompanyPaymentForm
            onPaymentAdded={handlePaymentAdded}
            companyId={company?._id}
            closeModal={() => setShowPaymentModal(false)}
            isSubmitting={isPaymentSubmitting}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CompanyDetailPage;
