import React, { useState } from "react";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addInvoice } from "../../api/invoices";
import { toast } from "react-toastify";
import { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import { useSelector } from "react-redux";

registerLocale("tr", tr);
setDefaultLocale("tr");

const CompanyInvoiceForm = ({ onInvoiceAdded, companyId, closeModal }) => {
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectedCustomer = useSelector(
    (state) => state.customers.selectedCustomer
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    if (!selectedCustomer?._id) {
      toast.error("Lütfen bir müşteri seçin.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    setIsSubmitting(true);
    const newInvoice = {
      invoiceNumber: invoiceNumber.toUpperCase(),
      date,
      customerId: selectedCustomer._id,
      amount: Number(amount),
      description: description.toUpperCase(),
      companyId,
    };

    try {
      // Fatura ekleme işlemi
      await addInvoice(newInvoice);

      // Modalı kapat
      closeModal();

      // Formu sıfırla
      setInvoiceNumber("");
      setDate(new Date());
      setAmount("");
      setDescription("");

      toast.success("Fatura başarıyla eklendi.", {
        position: "top-right",
        autoClose: 3000,
      });

      // `onInvoiceAdded` çağrısı ile yeni verilerle güncellenmesini sağla
      onInvoiceAdded(); // Bu, CompanyDetailPage'de `fetchCompany` fonksiyonunu tetikleyecek
    } catch (error) {
      console.error("Fatura eklerken hata:", error);
      toast.error("Fatura eklenirken bir hata oluştu.", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };


  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-4 border border-gray-300 rounded shadow-md p-5"
    >
      <div className="mb-4">
        <label className="block">Fatura No</label>
        <input
          type="text"
          value={invoiceNumber}
          onChange={(e) => setInvoiceNumber(e.target.value)}
          required
          className="w-full border px-4 py-2"
          maxLength={16}
        />
      </div>

      <div>
        <label className="block">Tarih</label>
        <DatePicker
          selected={date}
          onChange={(date) => setDate(date)}
          dateFormat="dd/MM/yyyy"
          locale="tr"
          className="w-full border px-4 py-2"
          required
        />
      </div>

      <div>
        <label className="block">Tutar</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
          className="w-full border px-4 py-2"
          step="0.01"
          min="0.01"
          max="100000000"
        />
      </div>

      <div>
        <label className="block">Açıklama</label>
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className="w-full border px-4 py-2"
          maxLength={50}
        />
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={closeModal}
          className="bg-gray-500 text-white px-4 py-2 rounded-lg"
        >
          Kapat
        </button>
        <button
          type="submit"
          className="bg-red-600 px-2 py-1 text-white rounded-lg hover:bg-red-300 hover:text-black transition"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Yükleniyor..." : "Fatura Ekle"}
        </button>
      </div>
    </form>
  );
};

export default CompanyInvoiceForm;
