import React from "react";
import LoginForm from "../../components/Login/LoginForm";

const LoginPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-gray-300 to-orange-500">
      <div className="bg-white rounded-lg shadow-lg p-8 w-96">
        <div className="text-center mb-6">
          {/* <img
            src={require("../../../public/favicon.ico")}
            alt="Logo"
            className="mx-auto mb-4"
          /> */}
          <h1 className="text-2xl font-bold text-gray-700">
            Cari Takip Sistemi
          </h1>
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;
