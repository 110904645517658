import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../redux/slices/authslice";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    const testUsername = process.env.REACT_APP_TEST_USERNAME;
    const testPassword = process.env.REACT_APP_TEST_PASSWORD;

    if (username === testUsername && password === testPassword) {
      dispatch(
        loginSuccess({
          firstName: process.env.REACT_APP_TEST_FIRSTNAME,
          lastName: process.env.REACT_APP_TEST_LASTNAME,
        })
      );
      navigate("/");
    } else {
      setError("Geçersiz kullanıcı adı veya şifre");
    }
  };

  return (
    <form onSubmit={handleLogin} className="space-y-4">
      {error && <p className="text-red-500 text-sm">{error}</p>}
      <div>
        <label
          htmlFor="username"
          className="block text-sm font-medium text-gray-700"
        >
          Kullanıcı Adı:
        </label>
        <input
          id="username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border rounded-lg text-gray-700 focus:ring-orange-500 focus:border-orange-500"
          placeholder="Kullanıcı adınızı girin"
        />
      </div>
      <div>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          Şifre:
        </label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border rounded-lg text-gray-700 focus:ring-orange-500 focus:border-orange-500"
          placeholder="Şifrenizi girin"
        />
      </div>
      <button
        type="submit"
        className="w-full bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600"
      >
        Giriş Yap
      </button>
    </form>
  );
};

export default LoginForm;
