import { configureStore } from "@reduxjs/toolkit";
import companiesReducer from "./slices/companiesSlice";
import invoicesReducer from "./slices/invoicesSlice";
import paymentsReducer from "./slices/paymentsSlice";
import customersReducer from "./slices/customersSlice";
import authReducer from "./slices/authslice";


const store = configureStore({
  reducer: {
    companies: companiesReducer,
    invoices: invoicesReducer,
    payments: paymentsReducer,
    customers: customersReducer,
    auth: authReducer,
  },
});

export default store;
