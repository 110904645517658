import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getCompanies = async (customerId) => {
  try {
    const response = await axios.get(`${API_URL}companies`, {
      params: { customerId },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error fetching companies: " + error.message);
  }
};

export const addCompany = async (company, customerId) => {
  try {
    const response = await axios.post(`${API_URL}companies`, company, {
      headers: { "customer-id": customerId }, 
    });
    return { message: "Şirket başarıyla eklendi.", company: response.data };
  } catch (error) {
    console.error(
      "Şirket ekleme hatası:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateCompany = async (companyData, customerId) => {
  if (!companyData || !companyData._id || !customerId) {
    throw new Error("Geçersiz veriler: _id ve customerId gerekli.");
  }

  try {
    const response = await fetch(`${API_URL}companies/${companyData._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "customer-id": customerId,
      },
      body: JSON.stringify(companyData),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data; 
  } catch (error) {
    console.error("API update error:", error);
    throw error;
  }
};

export const deleteCompany = async (companyId, customerId) => {
  try {
    const response = await axios.delete(`${API_URL}companies/${companyId}`, {
      headers: { "customer-id": customerId },
    });
    return {
      message: "Şirket ve ilişkili faturalar başarıyla silindi.",
      data: response.data,
    };
  } catch (error) {
    console.error(
      "Şirket silme hatası:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getCompanyDetails = async (id, customerId) => {
  try {
    const response = await axios.get(`${API_URL}companies/${id}`, {
      headers: { "customer-id": customerId },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Şirket detayları getirme hatası:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const deactivateCompany = async (companyId, customerId) => {
  try {
    const response = await axios.put(
      `${API_URL}companies/${companyId}/deactivate`,
      {},
      {
        headers: { "customer-id": customerId },
      }
    );
    return {
      message: "Şirket başarıyla deaktif hale getirildi.",
      company: response.data,
    };
  } catch (error) {
    console.error(
      "Şirket deaktif etme hatası:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
