import React, { useState, useEffect } from "react";

const Card = ({ customer }) => {
  const [companyCount, setCompanyCount] = useState(0);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [paymentCount, setPaymentCount] = useState(0);

  useEffect(() => {
    const animateCounter = (target, setter) => {
      let count = 0;
      const interval = setInterval(() => {
        if (count < target) {
          count++;
          setter(count);
        } else {
          clearInterval(interval);
        }
      }, 5);
    };

    animateCounter(234, setCompanyCount);
    animateCounter(1242, setInvoiceCount);
    animateCounter(1452, setPaymentCount);
  }, []);

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 m-4 max-w-sm w-full">
      <h3 className="text-2xl font-semibold text-gray-800 mb-4">
        {customer.name}
      </h3>
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <h4 className="text-lg font-medium text-gray-700">Şirket Sayısı</h4>
          <p className="text-sm text-gray-500">{companyCount}</p>
        </div>
        <div className="flex items-center justify-between">
          <h4 className="text-lg font-medium text-gray-700">Fatura Sayısı</h4>
          <p className="text-sm text-gray-500">{invoiceCount}</p>
        </div>
        <div className="flex items-center justify-between">
          <h4 className="text-lg font-medium text-gray-700">Ödeme Sayısı</h4>
          <p className="text-sm text-gray-500">{paymentCount}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
